import React from 'react'
import { DictionaryType } from './types'

const English: DictionaryType = {
  id: 'english',
  labels: {
    pay: 'Payment',
    allergens: 'Allergens',
    address: 'Address',
    datum: 'Date',
    where: 'Where to?',
    whereRetail: 'Area',
    discount: 'Discount',
    productDescription: 'Item description',
    insertProductIntoCart: 'Add to order',
    activeDeliveryTimes: 'Active delivery times',
    newDeliveryTime: 'New delivery time',
    order: 'Order',
    deliveryTime: 'Delivery time',
    subtotal: 'Subtotal',
    orderSummary: 'Order summary',
    priceTotal: 'Total',
    price: 'Price',
    netPrice: 'excl. VAT',
    grossPrice: 'incl. VAT',
    cartage: 'Collection',
    shipping: 'Delivery',
    id: 'IC / Company ID',
    id2: 'DIC / Tax ID',
    back: 'Back',
    lostPassword: 'Forgotten password?',
    commonlyUsed: 'Commonly used',
    suitableFor: (from: number, to: number) => {
      if (!to && from < 2) {
        return `Suitable for ${from} person`
      } else if (!to) {
        return `Suitable for ${from} people`
      }
      return `Suitable for ${from} - ${to} people`
    },
    accountRegistered: 'Already have an account?',
    productNotAvailable: 'For selected delivery date product is unavailable.',
    datesNull: 'Ordering system is currently closed. Try again later please.',
    appearance: 'Appearance',
    from: 'From',
    to: 'To',
    customer: 'Customer',
    user: 'User',
  },
  cart: {
    address: 'Address',
    datum: 'Date',
    printH1: 'Order Draft / Proposal',
  },
  tables: {
    orderHistoryList: {
      heading: 'Orders',
      created: 'Created',
      delivery: 'Delivered',
      docnum: 'Order number',
      grossprice: 'Price incl. VAT',
      netprice: 'Price excl. VAT',
      state: 'State',
      paystatus: 'Payment status',
      emptyLabel: 'You don\'t have any active orders.',
      emptyFilter: 'No orders match the filters',
      invoice: 'Invoice',
      actionname: 'Action name'
    },
    orderHistoryItemList: {
      code: 'Item code',
      label: 'Item name',
      amount: 'Amount',
      unit: 'Unit',
      price: 'Price',
      orderNumber: 'Order No.',
      back: 'Back to order list',
    }
  },
  dialogs: {
    changeDeliveryTime: {
      heading: 'Do you wish to change the date?',
      info: 'By changing the date, you will leave your open order for current date. You can return to this order anytime by selecting the current date.',
      note: 'Note: our system enables one open order (cart) per one date.',
      yes: 'Change delivery time',
      no: 'Close',
    },
    changeDeliveryAddress: {
      heading: 'Do you wish to change the area?',
      info: 'By changing the area you will cancel your order and start a new one.',
      yes: 'YES, cancel order',
      no: 'NO, continue with original order',
    },
  },
  tags: {
    new: 'New',
    advDays: (n: number) => {
      return `Order ${n} ${n === 1 ? 'day' : 'days'} in advance.`
    },
    collectionHint: 'Will be added collection fee',
    granularity: 'Order by',
    minOrder: 'Minimal order',
    maxOrder: 'Maximal order',
    time: 'Today until',
    timeHint: (time: string) => `This product must be ordered today until ${time} to be delivered on time`,
  },
  register: {
    heading: 'Don\'t have an account yet?',
    checkboxCreateAccount: 'I want to register',
    checkboxFill: 'Fill data from order form above.',
    personalConfirm: 'Uděluji souhlas společnosti EXPRESS DELI s.r.o. (IČ 2615 3882) se zpracováním výše uvedených údajů za účelem založení přístupu do uzavřeného objednávkového systému eOrder.',
    termsAndConditionsIntro: 'I agree with',
    termsAndConditionsLink: 'terms and conditions',

    street: 'Street',
    city: 'City',
    postcode: 'postcode',

    info1: '(TODO translate) V případě zájmu o registraci účtu pro objednání firemního cateringu prosím pošlete poptávku.',
    info2: '(TODO translate) Kromě doručení na Vámi specifikovanou adresu je možné Vaši objednávku vyzvednout v provozovně EXPRESS GRILL, Želetavská 1, Praha 4.',
  },
  forms: {
    order: {
      heading: 'Finish order',
      deliveryInformation: 'Delivery information',
      floor: 'Floor',
      room: 'Room',
      event: 'Event',
      person: 'Delivery contact',
      personRetail: 'Delivery contact',
      personName: 'Name and Surname',
      personPhone: 'Phone',
      paymentMethods: 'Payment method',
      additionalData: 'Additional information',
      note: 'Note',
      internalCode: 'Internal code',
      submit: 'Send order',
      invoiceInfo: 'Invoice info',
    },
    login: {
      heading: 'Login',
      email: 'Email',
      password: 'Password',
      rememberMe: 'Remember me?',
      submit: 'Log in',
      info: 'Don\'t have an account?',
      register: 'Register',
    },
    setEnvironment: {
      heading: 'Order set up',
      customer: 'Who is ordering today?',
      address: 'Where to we will deliver?',
      when: 'When will we deliver the order?',
      serving: 'How will we serve the order?',
      submit: 'Start ordering',
      orderInfo: 'Our offer changes over time. In order to show you our offer, select where to and when we will deliver your order.',
      calendarInfo: 'We accept orders upto 17.00 during workdays with next workday delivery.',
    },
    password: {
      oldPassword: 'Current password',
      newPassword: 'New password',
      success: 'Password has been successfully changed',
      submit: 'Confirm password change',
      resetTitle: 'Password reset',
      resetConfirm: 'Confirm password change',
      resetRequestTitle: 'Request password reset',
      resetRequestLabel: 'Enter your email',
      resetRequestSubmit: 'Send link for password reset',
    },
  },
  formValidators: {
    tooShort: (size: number) => `Value has to be at least ${size} characters long.`,
    tooLong: (size: number) => `Value has to be max ${size} characters long.`,
    notEmpty: 'This input field is required',
    password: 'Password does not meet criteria',
    invalidChars: (chars: string) => `Contains invalid characters: ${chars}`,
    invalidEmail: 'This is not valid email address',
    paymethodSelect: 'Select paymethod',
  },
  search: {
    heading: 'Search',
    categories: 'Category',
    products: 'Products',
    info: (phrase: string, num: number) => `For the following search string <b>${phrase}</b> we have found ${num} <b>results</b>.`,
  },
  buttons: {
    showOffer: 'Show offer',
    myAccount: 'My account',
    search: 'Search',
    addToOrder: 'Add to order',
    changeDeliveryTime: 'Change delivery time',
    finishOrder: 'Continue to order summary',
    toCartPage: 'Continue to order page',
    backToEshop: 'Back to the shop',
    backToCart: 'Back to the order',
    paymentRepeat: 'Repeat payment',
    paygateGo: 'Continue to the pay gate',
    createNewOrder: 'Create new order',
    withoutRegistration: 'Continue without registration',
    withoutRegistrationDetail: 'Registration is optional at the end of the order',
    filter: 'Filter',
    filterUse: 'Use filter',
  },
  actions: {
    pay: 'Pay order',
    logout: 'Logout',
    anonymLogout: 'Cancel order',
    detailShow: 'Show product detail',
    detailHide: 'Hide product description',
    close: 'Close',
    readActuality: 'Read actuality',
    showMore: 'Show more',
    showLess: 'Show less',
    contact: 'Contact us.',
    loadMore: 'Load more',
  },
  placeholders: {
    search: 'Enter search',
    choose: 'Choose…',
    deliveryTime: 'Choose delivery time…',
    anotherPaymethod: 'Choose another pay method',
    paymethod: 'Choose pay method',
  },
  info: {
    needHelp: 'Need help?',
    emptyOrder: 'You have not added anything to your order',
    minOrder: (amount: string) => `To reach minimum order amount <b>${amount} Kč is missing</b>.`,
  },
  thankYou: {
    heading: 'Thank you for your order',
    headingCanceled: 'Canceled payment',
    descriptionCanceled: 'Your order has not been paid',
    descriptionCanceledInstructions: 'You can return to payment gateway and repeat the payment',
    title: 'Order was sent! Thank you.',
    description: 'Order will be processed as soon as possible during our operating hours.',
    orderNumber: 'Reference number',
    orderPaid: 'Order successfully paid',
    headingError: 'Payment error',
    orderPaidError: 'While processing the payment, error occured.',
    orderPaidDescriptionError: 'While processing the payment, error occured. Please try again or contact us.',
    paygateDescription: 'You have chosen payment method - Card online (payment gateway CSOB). You will now be redirected to the payment gateway.',
    paygateDescriptionSodexo: 'You have chosen payment method - Card online (payment gateway Sodexo). You will now be redirected to the payment gateway.',
  },
  account: {
    accountTitle: 'Account',
    passwordTitle: 'Password change',
    customersTitle: 'Customers',
    historyTitle: 'History',
  },
  html: {
    addProductToCartInfo:
      `
      <p> While selecting delivery window, it is necessary to take into account time for the set up your order. </p>
      <p>
        <b>Example:</b> If your event is supposed to start at 9:00 select delivery window 7:45-8:30.
        Customer claims that during selected period will accept the delivery of the order. Thank you for keeping this in mind when selecting the delivery window.
      </p>
      `,
    addProductToCartInfoRetail:
      `
      <p> On the day of delivery, we will inform you with exact delivery time interval. Thank you for your cooperation. </p>
      `
  },
  cookie: {
    consent: 'For propper usage our website requires basic cookies. By using our website you agree to usage of these cookies.',
    ok: 'I do understand',
  },
  errors: {
    resetPasswordInvalidToken: 'Link is not valid anymore. Request a new link.',
    changePassword404: 'Password change unsuccessful. Original password did not match.',
    page404: 'Page does not exist, continue to the',
    page404Link: 'shop',
    page404WrongSegment: ({ segment, Link }) => <div>You are logged in {segment === 'r' ? 'private' : 'corporate'} segment, to continue please <Link>logout here</Link>. Your unfinnished order will remain in our system.</div>,
    unknownEmail: 'Invalid email',
    credentials: 'Wrong credentials',
    AUTH: 'Unsuccessful login. Please try again or request a password reset.',
    OTHER: 'An application error occurred, a system alert was created. Please try again or contact us.',
    DATE: 'It is not possible to create an order for this day. Please select a different date.',
    MAXQTY: 'By merging the delivery times, maximum allowed amount for given product was exceeded.',
    PAYNF: 'Order not found, please contact us.',
    PAID: 'Order has already been paid.',
    QTYLT: 'Selected amount is less than minimum order.',
    QTYGT: 'Selected amount is more than maximum order.',
    QTYDMG: 'Quantity doesn\'t match granularity',
    MAIL: 'This email address cannot be used for a new registration. It is used for another account. Please enter a different email.',
  },
  footer: {
    about: 'About us',
    actuality: 'Actuality',
    actualityMore: 'Show more actualities',
  }
}

export default English
