import React, { useEffect } from 'react'
import { path, map, find, compose, length } from 'ramda'
import { connect } from 'react-redux'
import { createForm } from '../../hocs/form'
import { getLists, getLogout, postLanguage } from '../../store/actions/api'
import { selectDeliveryDates, selectLanguage } from '../../store/selectors/api'
import Loader from '../Loading'
import { useDictionary, makeUnbreakable, passArguments } from '../../dictionary'
import Flag from '../Flag'
import moment from 'moment'
import { useAnonym, useRetail } from '../../hooks/api'
import { useConfigParam } from '../../hooks/config'
import EnvironmentAutoSender from './EnvironmentAutoSender'

export const Form = createForm(['choose'], {
  Customer: { type: 'select', key: 'id_customer' },
  Address: { type: 'select', key: 'id_dlv_addr' },
  Calendar: { type: 'calendar', key: 'date' },
  ServeRadios: { type: 'select-radios', key: 'id_serving' },
  Language: { type: 'select', key: 'lang' },
})

export const languageInputComponents = {
  Option: ({ innerProps, data }: any) => {
    return <div className="clickable" {...innerProps}><Flag language={path(['label'], data)}/></div>
  },
  SingleValue: ({ data }: { data: { label:string, value: string } }) => {
    return <Flag language={data.label} style={{transform: 'translate(-2px, 8px)'}} />
  },
}

type Props = {
  login: object,
  chosenCustomer: undefined | number,
  serveOptions: object[] | undefined,
  languages: object[] | undefined,
  getLogout: () => void,
  postLanguage: (a: any) => any,
  getLists: () => void,
  chosenLanguage: undefined | string,
  defaultLanguageValue: undefined | string,
  deliveryDates: {date: string}[] | undefined,
}

const isCustomerValid = (chosenCustomer: undefined | number) => Boolean(chosenCustomer) || chosenCustomer === 0

// @TODO what is the correct type here?
const EnvironmentSetter: any = ({ login, languages, chosenCustomer, defaultLanguageValue, serveOptions = [], getLogout, getLists, chosenLanguage, postLanguage, deliveryDates }: Props) => {
  const dictionary = useDictionary()
  const isAnonym = useAnonym()
  const isRetail = useRetail()
  const shopName = useConfigParam('shop_name')

  const defaultLanguage = {
    label: defaultLanguageValue,
    value: defaultLanguageValue,
  }

  // Fetch lists on mount
  useEffect(() => {
    getLists()
  }, [])

  // Fetch language after lang changes
  useEffect(() => {
    if (chosenLanguage) {
      // @ts-ignore
      postLanguage({ lang: chosenLanguage }, () => getLists(true))
    }
  }, [chosenLanguage || defaultLanguage.value])

  const customerOptions = map(customer => ({
    label: path(['name'], customer),
    value: path(['id'], customer),
  }), path(['customers'], login) || [])

  const addressOptions = !isCustomerValid(chosenCustomer) ? [] : compose(
    customer => customer && map(address => ({
      label: path(['addr'], address),
      value: path(['id'], address),
    }), path(['ship_addrs'], customer) || []),
    find(customer => path(['id'], customer) === chosenCustomer),
  )(path(['customers'], login) || [])

  const defaultCustomer = length(path(['customers'], login) || []) === 1
    ? { label: path(['customers', 0, 'name'], login), value: path(['customers', 0, 'id'], login)}
    : undefined

  const defaultAddress = !isCustomerValid(chosenCustomer) ? undefined : compose(
    address => !address ? undefined : ({
      label: path(['addr'], address),
      value: path(['id'], address),
    }),
    customer => customer && find(address => Boolean(path(['default'], address)), path(['ship_addrs'], customer) || []),
    find(customer => path(['id'], customer) === chosenCustomer),
  )(path(['customers'], login) || [])
  // const defaultAddress = chosenCustomer && length(path(['customerRegister', chosenCustomer, 'ship_addrs'], login) || []) === 1
  //   ? { label: path(['customerRegister', chosenCustomer, 'ship_addrs', 0, 'addr'], login), value: path(['customerRegister', chosenCustomer, 'ship_addrs', 0, 'id'], login) }
  //   : undefined

  const minDateNum = path(['customerRegister', chosenCustomer || '', 'ord_adv_min_days'], login)
  const maxDateNum = path(['customerRegister', chosenCustomer || '', 'ord_adv_max_days'], login)

  let minDate = new Date()
  if (minDateNum) {
    // @ts-ignore
    minDate.setDate(minDate.getDate()+minDateNum)
  }

  let maxDate = new Date()
  if (maxDateNum) {
    // @ts-ignore
    maxDate.setDate(maxDate.getDate()+maxDateNum)
  } else {
    maxDate.setDate(maxDate.getDate()+30)
  }

  return (
    <Loader id="lists">
      <div className="dialog-content">
        <EnvironmentAutoSender />
        <div className="dialog-content-inner position-relative desktop-overflow-visible">
          <a className="topleft" href="#logout" onClick={() => getLogout()}><i className="fas fa-chevron-left" /><span> {dictionary.actions.logout}</span></a>
          <Form.Language
            className="select-language"
            isSearchable={false}
            defaultValue={defaultLanguage}
            options={map(lan => ({
              label: path(['lang'], lan),
              value: path(['lang'], lan),
            }), languages || [])}
            components={languageInputComponents}
          />
          <Form.Error />
          <h1> {dictionary.forms.setEnvironment.heading} </h1>
          {
            !deliveryDates || path(['length'], deliveryDates) === 0
            ? <div className="mt-2">
              { dictionary.labels.datesNull }
            </div>
            : <>
              <div className="text-left">
                {
                  !isAnonym ? null :
                  <div>
                    <div className="pt-1">
                      { passArguments(dictionary.labels.accountRegistered, {s: shopName}) }
                      <a href="#login" onClick={() => getLogout()} className="bold"> { dictionary.forms.login.submit } </a>
                    </div>
                    <div className="line-after pb-1 pt-1" />
                  </div>
                }

                {
                  !isRetail ? null :
                  <div>
                    <p>{dictionary.forms.setEnvironment.orderInfo}</p>
                  </div>
                }

                <label className={`mt-1 mb-decent d-block${isAnonym ? ' d-none' : ''}`}> {dictionary.forms.setEnvironment.customer} </label>
                <Form.Customer
                  className={isAnonym ? 'd-none' : ''}
                  placeholder={dictionary.placeholders.choose}
                  options={customerOptions}
                  defaultValue={defaultCustomer}
                />
                {
                  !isCustomerValid(chosenCustomer) ? null :
                  <React.Fragment>
                    <label className="mt-1 mb-decent d-block"> {dictionary.forms.setEnvironment.address} </label>
                    <Form.Address
                      placeholder={dictionary.placeholders.choose}
                      key={chosenCustomer}
                      options={addressOptions}
                      defaultValue={defaultAddress}
                    />
                  </React.Fragment>
                }

                {
                  isCustomerValid(chosenCustomer) || defaultCustomer
                  ? <React.Fragment>
                    <label className="mt-1 mb-decent d-block"> {dictionary.forms.setEnvironment.when} </label>

                    <Form.Calendar
                      view="month"
                      minDate={minDate}
                      maxDate={maxDate}
                      defaultValue={moment(path([0, 'date'], deliveryDates), 'YYYY-MM-DD').toDate()}
                      tileDisabled={({ date }: { date: Date }) => {
                        return !Boolean(find(d => d.date === moment(date).format('YYYY-MM-DD'), deliveryDates || []))
                      }}
                    />

                    <div className="info position-relative mt-2">
                      <i className="fas fa-info-circle" />
                      <div>
                        <p>{makeUnbreakable(dictionary.forms.setEnvironment.calendarInfo)}</p>
                      </div>
                    </div>

                    {
                      serveOptions.length === 1 ? null :
                      <label className="mt-1 mb-decent d-block"> {dictionary.forms.setEnvironment.serving} </label>
                    }
                    <Form.ServeRadios
                      hideIfOnlyOption
                      defaultValue={serveOptions.length === 1 ? path([0, 'id'], serveOptions) : undefined}
                      options={map(option => ({ label: path(['val'], option), value: path(['id'], option) }), serveOptions)}
                    />
                  </React.Fragment>
                  : null
                }

              </div>

              <Form.Submit className="btn"> {dictionary.forms.setEnvironment.submit} </Form.Submit>
            </>
          }
        </div>
      </div>
    </Loader>
  )
}

export default connect(state => ({
  login: path(['api', 'login'], state),
  chosenCustomer: path(['fields', 'values', 'choose', 'id_customer', 'value'], state),
  chosenLanguage: path(['fields', 'values', 'choose', 'lang', 'value'], state),
  defaultLanguageValue: selectLanguage(state).chosen,
  serveOptions: path(['api', 'lists', 'ProdVar'], state),
  languages: path(['api', 'languages', 'list'], state),
  deliveryDates: selectDeliveryDates(state),
}), { getLogout, getLists, postLanguage })(EnvironmentSetter)

