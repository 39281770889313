import { path } from 'ramda'
import { FetchParamsType } from '../types/fetch'
import { ThemeType } from '../types/ui'
import { selectLanguage } from '../selectors/api'

export const FETCH_RESOURCE_START = 'FETCH_RESOURCE_START'
export const FETCH_RESOURCE_COMPLETE = 'FETCH_RESOURCE_COMPLETE'
export const FETCH_RESOURCE_ERROR = 'FETCH_RESOURCE_ERROR'
export const FETCH_RESOURCE_CANCELED = 'FETCH_RESOURCE_CANCELED'
export const SIGN_OUT = 'SIGN_OUT'
export const DISMISS_ERROR = 'DISMISS_ERROR'

interface DispatchType {
  type: 'FETCH_RESOURCE_START' | 'FETCH_RESOURCE_COMPLETE' | 'FETCH_RESOURCE_ERROR' | 'SIGN_OUT',
  sync?: boolean,
  payload: object,
}

export const dismissError = (formPath: string[]) => ({
  type: DISMISS_ERROR,
  payload: {
    formPath,
  }
})

// @ts-ignore
export const signOut = () => ({ getState }: { getState: () => any }) => ({
  type: SIGN_OUT,
  sync: true,
  payload: {
    languageAfterLogout: path(['chosen'], selectLanguage(getState())),
  }
})

export const fetchResource = ({
  onSuccess,
  onError,
  forceFetchIfAlreadySaved = false,
  sync = false,
  ...options
}: FetchParamsType) => {
  const res = async (dispatch: (p: DispatchType) => void, fetch: Function, getState: Function) => {
    const response = await fetch(options)

    if (response) {
      // Is it JSON?
      response.json().then((res: any) => {

        // not authorized?
        if (response.status === 401 || response.status === 403) {
          if (onError) {
            onError({ dispatch })
          }
          // @ts-ignore
          dispatch(signOut())
        }

        if (response.status >= 300) {
          if (onError) {
            onError({ dispatch })
          }
          return dispatch({ type: FETCH_RESOURCE_ERROR, payload: { ...options, status: response.status, data: res} })
        }

        if (onSuccess) {
          onSuccess({
            status: response.status,
            dispatch,
            data: res,
            getState,
          })
        }
        dispatch({ type: FETCH_RESOURCE_COMPLETE, sync, payload: { ...options, status: response.status, data: res}})
      })
      // Response is not a JSON, so call {onSuccess} with no data
      .catch((error: any) => {

        if (response.status >= 300) {
          if (onError) {
            onError({ dispatch })
          }
          return dispatch({ type: FETCH_RESOURCE_ERROR, payload: { ...options, status: response.status, data: null } })
        }

        if (onSuccess)
          onSuccess({
            status: response.status,
            dispatch,
            data: null,
          })
        dispatch({ type: FETCH_RESOURCE_COMPLETE, sync, payload: { ...options, status: response.status, } })
      })
    } else {
      if (onError) {
        onError({ dispatch })
      }

      dispatch({ type: FETCH_RESOURCE_ERROR, payload: { ...options }})
    }
  }

  return ({ dispatch, fetch, getState }: { dispatch: (p: DispatchType) => void, fetch: Function, getState: Function }) => {
    if (!options.force && !forceFetchIfAlreadySaved && options.method === 'get' && path([options.method, ...options.resourcePath, 'type'], getState().fetch.status) === 'saved') {
      if (options.id !== 'logout') {
        return {
          type: FETCH_RESOURCE_CANCELED,
          payload: {...options},
        }
      }
    }
    // Fetch the resource only if it is not already pending.
    if (path([options.method, ...options.resourcePath, 'type'], getState().fetch.status) !== 'pending') {
      res(dispatch, fetch, getState)
    }
    return {
      type: FETCH_RESOURCE_START,
      payload: {...options},
    }
  }
}
