import React from 'react'
import { Link } from '../Link'
import { useDictionary } from '../../dictionary'
import { path } from 'ramda'
import { connect } from 'react-redux'
import { selectSignedUser } from '../../store/selectors/api'
import LanguageChanger from './LanguageChanger'
import LogoutButton from './LogoutButton'
import ChangeEnvironmentButton from './ChangeEnvironmentButton'
import { useAnonym } from '../../hooks/api'

type Props = {
  user: unknown,
  userName: unknown,
}

const MobileTopSubmenu = ({ user, userName }: Props) => {
  const dictionary = useDictionary()
  const isAnonym = useAnonym()

  if (isAnonym) {
    return (
      <div className="top-menu-submenu">
        <div className="item">
          <div className="item-content text-right">
            <LogoutButton />
            <LanguageChanger />
          </div>
        </div>
        <div className="item">
          <div className="item-content text-right">
            <ChangeEnvironmentButton />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="top-menu-submenu">
      <div className="item">
        <div className="item-content">
          <i className="fas fa-user-circle mr-decent" />
          <span>{`${userName || ''}`}</span>
        </div>
      </div>
      <div className="item">
        <div className="item-content">
          <i className="fas fa-briefcase mr-decent" />
          <span>{path(['name'], user) || ''}</span>
        </div>
      </div>
      <div className="item">
        <div className="item-content">
          <LanguageChanger />
          <Link to="/me/password" className="btn btn--secondary d-block">{dictionary.buttons.myAccount}</Link>
          <LogoutButton />
        </div>
      </div>
      <div className="item">
        <div className="item-content">
          <ChangeEnvironmentButton />
        </div>
      </div>
    </div>
  )
}

export default connect((state: any) => ({
  userName: path(['api', 'login', 'user'], state) || '',
  user: selectSignedUser(state),
}))(MobileTopSubmenu)
