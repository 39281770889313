import React, { useState } from 'react'
import { Link } from '../Link'
import { path } from 'ramda'
import { connect } from 'react-redux'
import LogoutButton from './LogoutButton'
import { AppStateType } from '../../store/types'
import { selectSignedUser } from '../../store/selectors/api'
import LanguageChanger from './LanguageChanger'
import { useDictionary } from '../../dictionary'
import DropDown from './DropDown'
import ChangeEnvironmentButton from './ChangeEnvironmentButton'
import { useAnonym } from '../../hooks/api'

type Props = {
  user: unknown,
  userName: unknown,
}

const TopRightMenu = ({ user, userName }: Props) => {
  const [opened, open] = useState(false)
  const isAnonym = useAnonym()
  const dictionary = useDictionary()
  return (
    <ul className="header-list header-list--right header-list--small position-absolute line-separated mt-0">
      <li>
        <ChangeEnvironmentButton />
      </li>
      {
        isAnonym ? null :
        <React.Fragment>
          <li
            className="position-relative clickable"
            onClick={(e) => {
              e.stopPropagation()
              open(!opened)
            }}
          >
            <i className="fas fa-user-circle" />
            <span> {userName || ''} </span>
            <i className={`fas fa-angle-${opened ? 'up' : 'down'}`} />
            {
              !opened ? null :
              <DropDown open={open}>
                <div
                  className="drop-down text-center"
                  onClick={e => e.stopPropagation()}
                >
                  <div className="mb-1 pt-1">
                    <Link to="/me/password" className="btn btn--secondary d-block">{dictionary.buttons.myAccount}</Link>
                  </div>
                  <div className="pt-1">
                    <LogoutButton />
                  </div>
                </div>
              </DropDown>
            }
          </li>
          <li className="bold">
            <i className="fas fa-briefcase" />
            <span> {path(['name'], user) || ''} </span>
            {
              !path(['discount'], user) ? null :
              <div className="discount">
                <span>
                {
                  `${dictionary.labels.discount} ${path(['discount'], user)}%`
                }
                </span>
              </div>
            }
          </li>
        </React.Fragment>
      }
      {
        !isAnonym ? null : <li><LogoutButton /></li>
      }
      <LanguageChanger />
    </ul>
  )
}

export default connect((state: AppStateType) => ({
  userName: path(['api', 'login', 'user'], state) || '',
  user: selectSignedUser(state),
}))(TopRightMenu)
