import React from 'react'
import { connect, useSelector } from 'react-redux'
import { OrderType, OrderItemType, DeliveryTimeType } from '../../store/types/order'
import { deleteProductFromCart } from '../../store/actions/api'
import { selectDefaultCategoryUrl } from '../../store/selectors/api'
import Image from '../Image'
import Price from './Price'
import { useDictionary } from '../../dictionary'
import AmountChanger from './AmountChanger'
import { useMobileUi } from '../../hooks/ui'
import { Link } from '../Link'
import { selectSubcategoryIdFromCatId } from '../../store/selectors/api'
import { listItemConnection } from '../products/ListItem'

type Props = {
  product: OrderItemType,
  cart: OrderType,
  delivery: DeliveryTimeType,
  section?: unknown,
  deleteProductFromCart: (params: { cartId: number, timeCode: string, productId: number }) => void,
}

const CartListItem = ({ product, deleteProductFromCart, cart, delivery, section }: Props) => {
  const dictionary =  useDictionary()
  const isMobile = useMobileUi()
  const defaultCategoryUrl = useSelector(selectDefaultCategoryUrl)
  const realCatId = useSelector(selectSubcategoryIdFromCatId(product.cat_id))
  const link = section
    ? `/${defaultCategoryUrl}/category/${realCatId}/section/${section}/product/${product.url}#topMenu`
    : `/${defaultCategoryUrl}/category/${realCatId}/product/${product.url}#topMenu`

  return (
    <div className={`order-box-item-row${product.display.type === 'norm' ? '' : ' order-box-item-row--grey'}`}>
      <div className="order-box-item-row-content">
        <div className="order-item-content d-flex position-relative">
          {
            !product.display['ctrl-del'] ? null :
            <i className="fas fa-times clickable cancel-order" onClick={() => {
              if (global.confirm('Chete tento produkt odebrat z košíku?')) {
                deleteProductFromCart({ cartId: cart.id, productId: product.id, timeCode: delivery.timecode })
              }
            }}/>
          }
          {
            product.display.type !== 'norm' ? null :
            <div className="preview-container">
              <Image className="preview" code={product.image} small alt={product.label} />
            </div>
          }
          <div className={`text-left ${product.display.type === 'norm' ? 'w-80' : 'w-100'}`}>
            {
              product.display.type === 'norm'
              ?
                <div className="preview-text">
                  {
                    product.url && realCatId
                    ?
                      <Link to={link}>
                        {product.code} | {product.label}
                      </Link>
                    : `${product.code} | ${product.label}`
                  }
                </div>
              : product.display.type === 'ship'
              ? <div className="preview-text pl-0"><i className="fas fa-truck" /> <strong>{dictionary.labels.shipping}:</strong> {product.label} </div>
              : <div className="preview-text pl-0"><i className="fas fa-truck" /> <strong>{dictionary.labels.cartage}:</strong> {product.label} </div>
            }
            {
              isMobile ? null :
              <div className="text-right">
                <AmountChanger product={product} cart={cart} delivery={delivery} />
                <div className="d-inline-block">
                  <div>
                    <strong>
                      <Price gross={product.grossprice} net={product.netprice} />
                    </strong>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
        {
          !isMobile ? null :
          <div className="text-right mt-1">
            <AmountChanger product={product} cart={cart} delivery={delivery} />
            <div className="d-inline-block">
              <div>
                <strong>
                  <Price gross={product.grossprice} net={product.netprice} />
                </strong>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  )
}
// @ts-ignore
export default connect(listItemConnection, { deleteProductFromCart })(CartListItem)
