import React, { useState } from 'react'
import { path, find } from 'ramda'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from '../Link'
import { setUi } from '../../store/actions/ui'
import { DialogType } from '../../store/types/ui'
import DialogWrapper from './DialogWrapper'
import { useDictionary } from '../../dictionary'
import { postEnv, getOrder } from '../../store/actions/api'
import { selectDeliveryDates } from '../../store/selectors/api'
import { CalendarWithLocale } from '../../hocs/form'

type Props = {
  dialog: DialogType,
}

const DateFormat = 'YYYY-MM-DD'

const ChangeDatum = ({ dialog }: Props) => {
  const dictionary = useDictionary()
  const dispatch = useDispatch()
  const deliveryDates = useSelector(selectDeliveryDates)
  const currentDate = useSelector(path(['api', 'env', 'data', 'date']))
  const [date, setDate] = useState(currentDate)

  const dictDialog = dictionary.dialogs.changeDeliveryTime

  return (
    <DialogWrapper className="dialog-content dialog-content--with-max-width dialog-content--large dialog-content--no-overflow">
      <div>
        <h1>{dictDialog.heading}</h1>
        <p>{dictDialog.info}</p>

        <p><i>{dictDialog.note}</i></p>

        <CalendarWithLocale
          // @ts-ignore
          value={moment(date, DateFormat).toDate()}
          onChange={(val: any) => setDate(moment(val).format(DateFormat))}
          tileDisabled={({ date }: { date: Date }) => {
            // @ts-ignore
            return !Boolean(find(d => d?.date === moment(date).format(DateFormat), deliveryDates || []))
          }}
          // onChange={(val: any) => onChange(name, moment(val).format(DateFormat))}
          // value={state[name] && moment(state[name], DateFormat).toDate()}
        />

        <div className="mt-2 text-center">
          <span
            className="link link--underline mr-1"
            onClick={() => {
              if (date) {
                dispatch(postEnv({
                  date: date as string
                }, () => {
                  dispatch(getOrder(true))
                  dispatch(setUi({ openedDialog: { id: null } }))
                }))
              }
            }}
          >
            {dictionary.dialogs.changeDeliveryTime.yes}
          </span>

          <button className="btn btn--primary ml-1" onClick={() => dispatch(setUi({ openedDialog: { id: null } }))}>
            {dictionary.dialogs.changeDeliveryTime.no}
          </button>
        </div>
      </div>
    </DialogWrapper>
  )
}

export default ChangeDatum
