import { path, map, mapObjIndexed, reduce, merge, trim, groupBy, find, test, isEmpty } from 'ramda'
import moment from 'moment'
import { fetchResource, signOut } from './fetch'
import { setUi } from './ui'
import { clearForm } from './fields'
import { selectTheme } from '../selectors/ui'
import {
  LoginFormType,
  EnvFormType,
  OrderFormType,
} from '../types/api'
import { getSegment, getHost, getInstanceId, getExtsys } from '../../config'
import { convertArrayToObject, buildCategoryPaths, extractSearchParams } from '../../utils'

export const resourcePaths = {
  config: () => ['config'],
  order: () => ['order'],
  orderHistory: () => ['orderHistory'],
  orderHistoryItem: (id: string | number) => ['orderHistoryItem', `${id}`],
  createdOrder: () => ['order-created'],
  languages: () => ['languages'],
  login: () => ['login'],
  logout: () => ['logout'],
  lists: () => ['lists'],
  env: () => ['env'],
  categories: () => ['categories'],
  category: (url: string) => ['categories', 'urlRegister', url],
  product: (productUrl: string) => ['products', productUrl],
  productsFromCategory: (categoryId: number) => ['products', `${categoryId}`],
  searchProduct: (phrase: string) => ['search', 'product', phrase],
  searchCategory: (phrase: string) => ['search', 'category', phrase],
  payMethods: () => ['payMethods'],
  footerData: (category: string) => ['footer', category],
  contentData: (category: string, url: string) => ['content', category, url],
  contentDataList: (category: string) => ['content', category, 'list'],
  customer: (id: number) => ['customer', `${id}`],
  passwordChange: () => ['password', 'change'],
  passwordReset: () => ['password', 'reset'],
  passwordSend: () => ['password', 'send'],
  newPayment: (id: number | string ) => ['newpayment', `${id}`],
}

type OrderHistoryQueryType = {
  [param: string]: string | number
}

export const getOrderHistory = (query: OrderHistoryQueryType) => fetchResource({
  id: 'orderHistory',
  method: 'get',
  resource: '/orders',
  query,
  resourcePath: resourcePaths.orderHistory(),
  forceFetchIfAlreadySaved: true
})

export const getOrderHistoryItem = (id: string | number) => fetchResource({
  id: 'orderHistoryItem',
  method: 'get',
  resource: `/orders/${id}`,
  resourcePath: resourcePaths.orderHistoryItem(id)
})

export const getPayMethods = () => fetchResource({
  id: 'payMethods',
  method: 'get',
  resource: '/lovs/paymethods',
  resourcePath: resourcePaths.payMethods(),
})

export const getLanguages = () => fetchResource({
  method: 'get',
  resourcePath: resourcePaths.languages(),
  resource: `/lovs/langs/${getInstanceId()}`, //@TODO just testing here
  transformResponse: (data: any) => {
    return ({
      list: path(['langs'], data),
      default: find(language => path(['def'], language) === 'Y', path(['langs'], data) || []),
    })
  }
})

export const getConfig = () => fetchResource({
  method: 'get',
  resourcePath: resourcePaths.config(),
  resource: `/instances/${getHost()}`,
  transformResponse: (data: any) => {
    // @ts-ignore
    global.window.__CATERMAT_CONFIG__ = {
      // @ts-ignore
      ...global.window.__CATERMAT_CONFIG__,
      ...(path(['data'], data) || {}),
    }
    return data
  }
})

export const getCategories = () => fetchResource({
  method: 'get',
  resourcePath: resourcePaths.categories(),
  resource: '/categories',
  transformResponse: (data: any) => {
    return ({
      list: map(category => path(['id'], category), path(['data'], data) || []),
      register: convertArrayToObject(category => path(['id'], category))(path(['data'], data) || []),
      byUrl: mapObjIndexed(category => path(['id'], category))(convertArrayToObject(category => path(['url'], category))(path(['data'], data) || [])),
      // @ts-ignore
      subRegister: reduce(merge, {}, map(category => convertArrayToObject(c => path(['id'], c))(path(['categories'], category) || []),path(['data'], data) || [])),
      categoryPaths: buildCategoryPaths(path(['data'], data) || []),
    })
  }
})

export const getCategory = (url: string) => fetchResource({
  method: 'get',
  resourcePath: resourcePaths.category(url),
  resource: `/categories/${url}`,
})

export const getOrder = (force?: boolean) => fetchResource({
  method: 'get',
  resourcePath: resourcePaths.order(),
  resource: '/carts/present',
  sync: true,
  force,
})

export const getProduct = (productUrl: string) => fetchResource({
  method: 'get',
  resourcePath: resourcePaths.product(productUrl),
  resource: `/products/${productUrl}`,
})

export const getProductsFromCategory = (categoryId: number) => fetchResource({
  method: 'get',
  resourcePath: resourcePaths.productsFromCategory(categoryId),
  resource: `/products/categories/${categoryId}`,
  transformResponse: (data: any) => ({
    ...data,
    groups: path(['sections'], data) && groupBy(item => path(['section'], item) || 'other')(path(['data'], data) || []),
    sectionRegister: path(['sections'], data) && convertArrayToObject(section => path(['id'], section))(path(['sections'], data) || []),
  })
})

export const getLists = (force?: boolean) => fetchResource({
  method: 'get',
  resourcePath: resourcePaths.lists(),
  resource: '/lovs/all',
  force,
  transformResponse: (data: any) => ({
    ...data,
    Segments: convertArrayToObject(seg => path(['id'], seg))(path(['Segments'], data) || []),
    Units: convertArrayToObject(unit => path(['id'], unit))(path(['Units'], data) || []),
    deliveryTimesRegister: reduce(
      merge,
      // @ts-ignore
      {},
      map(segment => convertArrayToObject(time => path(['id'], time))(path(['dlv_times'], segment) || []), path(['Segments'], data) || [])
    ),
  })
})

export const getLogout = () => fetchResource({
  id: 'logout',
  method: 'get',
  resource: '/auth/logout',
  resourcePath: resourcePaths.logout(),
  sync: true,
  onSuccess: ({ dispatch }) => dispatch(signOut()),
  onError: ({ dispatch }) => dispatch(signOut()),
})

export const postLogin = (form: LoginFormType, withoutRegistration?: boolean) => fetchResource({
  id: 'login',
  method: 'post',
  resourcePath: resourcePaths.login(),
  resource: '/auth/login',
  // @TODO this cannot be synced because of included functions!
  // sync: true,
  body: {
    id_instance: getInstanceId(),
    extsys: getExtsys(),
    ...(withoutRegistration ? {} : {
      username: form.email,
      password: form.password,
    }),
    seg: getSegment()
      // : test(/^\/r/, window.location.pathname) ? 'R' : 'B',
  },
  transformResponse: (data: any) => ({
    ...data,
    withoutRegistration,
    customerRegister: mapObjIndexed(
      (customer: object) => ({
        ...customer,
        addressRegister: convertArrayToObject(address => path(['id'], address))(path(['ship_addrs'], customer) || []),
      })
    )(convertArrayToObject(customer => path(['id'], customer))(path(['customers'], data) || [])),
  })
})

export const postSendPasswordLogin = ({ username, seg }: { username: string, seg: 'B' | 'R' }) => fetchResource({
  method: 'post',
  resourcePath: resourcePaths.passwordSend(),
  resource: '/auth/login',
  body: {
    id_instance: getInstanceId(),
    extsys: getExtsys(),
    username,
    seg,
  },
  onSuccess: () => {
    window.location.href = "/"
  }
})

export const postEnv = (form: EnvFormType, onSuccess?: () => void) => fetchResource({
  id: 'env',
  method: 'post',
  resourcePath: resourcePaths.env(),
  resource: '/persons/me/env',
  onSuccess,
  body: form,
  sync: true
})

export const postLanguage = (form: EnvFormType, onSuccess?: () => void) => fetchResource({
  id: 'env',
  method: 'post',
  resourcePath: resourcePaths.env(),
  resource: '/persons/me/env',
  onSuccess,
  body: form,
  transformResponse: (data: any, state: any) => {
    return ({
      data: {
      // @ts-ignore
      ...(path(['env', 'data'], state) || {}),
      lang: path(['data', 'lang'], data),
    }})
  }
})

export const postProductToOrder = ({ cartId, timeCode, productId, amount, ...other }: {
  cartId: number,
  timeCode: string,
  productId: number,
  amount: number,
  [item: string]: any,
}) => fetchResource({
  id: 'product-to-cart',
  sync: true,
  method: 'post',
  resourcePath: resourcePaths.order(), // Same path as order itself?
  resource: `/carts/${cartId}/deliveries/${trim(timeCode)}/products`,
  body: {
    id_product: productId,
    quantity: amount,
  },
  ...other,
})

export const putProductInCart = ({ cartId, timeCode, productId, body }: { cartId: number, timeCode: string, productId: number, body: object }) => fetchResource({
  method: 'put',
  resourcePath: resourcePaths.order(), // returns an order
  resource: `/carts/${cartId}/deliveries/${trim(timeCode)}/products/${productId}`,
  sync: true,
  body,
})

export const deleteProductFromCart = ({ cartId, timeCode, productId, body }: { cartId: number, timeCode: string, productId: number, body: object }) => fetchResource({
  method: 'delete',
  resourcePath: resourcePaths.order(),
  resource: `/carts/${cartId}/deliveries/${trim(timeCode)}/products/${productId}`,
  sync: true,
})

export const putDeliveryTime = ({ cartId, timeCode, newTimeCode }: { cartId: number, timeCode: string, newTimeCode: string }) => fetchResource({
  method: 'put',
  resourcePath: resourcePaths.order(),
  resource: `/carts/${cartId}/deliveries/${timeCode}`,
  sync: true,
  body: {
    timecode: newTimeCode,
  },
  onSuccess: ({ dispatch }) => dispatch(setUi({ openedDialog: { id: null }})),
})

export const deleteDeliveryTime = ({ cartId, timeCode }: { cartId: number, timeCode: string }) => fetchResource({
  method: 'delete',
  resourcePath: resourcePaths.order(), // returns an order
  resource: `/carts/${cartId}/deliveries/${trim(timeCode)}`,
  sync: true,
})

export const getProductSearch = ({ phrase }: { phrase: string }) => fetchResource({
  method: 'get',
  resourcePath: resourcePaths.searchProduct(phrase),
  resource: `/products/?q=${phrase}&limit=100`,
})

export const getCategorySearch = ({ phrase }: { phrase: string }) => fetchResource({
  method: 'get',
  resourcePath: resourcePaths.searchCategory(phrase),
  resource: `/categories?q=${phrase}&limit=100`,
})

export const postOrder = (orderForm: OrderFormType, register?: boolean) => fetchResource({
  id: 'post-order',
  method: 'post',
  resourcePath: resourcePaths.createdOrder(),
  resource: '/orders/',
  body: orderForm,
  query: register ? {register: 'Y'} : undefined,
  sync: true,
  onSuccess: ({ dispatch, data }) => {
    // if (register) {
    //   // console.log('TODO register success', data)
    // } else {
      // We're using timeout to ensure that it will dispatch after new token is saved
      setTimeout(() => dispatch(getOrder(true)), 100)
    // }
  },
})

export type GetFooterDataParams = { category: 'company' | 'news', limit: number }
export const getFooterData = (query: GetFooterDataParams) => fetchResource({
  method: 'get',
  resourcePath: resourcePaths.footerData(query.category),
  resource: `/content?category=${query.category}&limit=${query.limit}`,
  transformResponse: (data: any) => ({
    ...data,
    register: convertArrayToObject(item => path(['id'], item))(path(['data'], data) || []),
  }),
  onSuccess: ({ dispatch, data, getState }) => {
    if (path(['category'], query) === 'news' && path(['data', 0], data)) {
      const state = getState()
      const theme = selectTheme(state)
      if (moment().format('DD') !== path(['ui', 'lastNewDayOpened', theme], state)) {
        // Display only if we don't use auto-login
        if (!Boolean(path(['address'], extractSearchParams(window.location.search || '')))) {
          dispatch(setUi({ openedDialog: { id: 'first-actuality' }}))
        }
      }
    }
  }
})

export const getContentDataList = (query: { category: string, limit?: number, offset?: number }, loadMore?: boolean) => fetchResource({
  method: 'get',
  resourcePath: resourcePaths.contentDataList(query.category),
  resource: `/content`,
  query,
  forceFetchIfAlreadySaved: loadMore,
  transformResponse: (data: any, state: any) => {
    const currentList = path(['content', query.category, 'list', 'data'], state) as any[] | undefined

    if (currentList && loadMore) {
      return ({
        data: [...currentList, ...(data.data || [])],
        noMoreData: isEmpty(data.data || []),
      })
    }

    return data
  }
})

export const getContentData = (category: string, url: string) => fetchResource({
  method: 'get',
  resourcePath: resourcePaths.contentData(category, url),
  resource: `/content/${category}/${url}`
})

export const getCustomer = (id: number) => fetchResource({
  method: 'get',
  resourcePath: resourcePaths.customer(id),
  resource: `/customers/${id}`,
  forceFetchIfAlreadySaved: true, // this is here to load new frequently used field items after success order
})

export const putPassword = ({ oldPassword, newPassword }: { oldPassword: string, newPassword: string }) => fetchResource({
  method: 'put',
  resourcePath: resourcePaths.passwordChange(),
  resource: '/persons/password',
  onSuccess: ({ dispatch }) => {
    dispatch(clearForm(resourcePaths.passwordChange()))
  },
  body: {
    old: oldPassword,
    new: newPassword,
  }
})

export const putPasswordReset = ({ token, newPassword }: { token: string, newPassword: string }) => fetchResource({
  method: 'put',
  resourcePath: resourcePaths.passwordReset(),
  resource: '/persons/password',
  headers: {
    Authorization: `Bearer ${token}`,
  },
  body: {
    new: newPassword,
  },
  onSuccess: () => {
    // window.history.pushState({}, document.title, window.location.pathname)
    window.location.href = '/'
  },
})

export const postNewPayment = ({ payId, paymethod, onSuccess }: { payId: string | number, paymethod?: string, onSuccess?: (a: any) => any }) => fetchResource({
  method: 'post',
  resource: '/orders/dopay',
  resourcePath: resourcePaths.newPayment(payId),
  body: {
    // @ts-ignore
    pay_id: parseInt(payId, 10),
    ...(paymethod ? { paymethod } : {}),
  },
  onSuccess: ({ data, status }) => {
    if (data.redir) {
      window.location.href = data.redir
    }
    if (onSuccess) {
      onSuccess({ data, status })
    }
  }
})