import React from 'react'
import { connect, useSelector } from 'react-redux'
import { path, compose } from 'ramda'
import { fetchOnMount } from '../hocs/fetch'
import { getLanguages, getConfig } from '../store/actions/api'
import { anonymousLoginEnabled } from '../config'
import Loader from './Loading'
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
  useLocation,
} from "react-router-dom"
import { ThemeType } from '../store/types/ui'
import { selectTheme } from '../store/selectors/ui'
import Header from '../components/header'
import Footer from '../components/footer'
import IndexPage from '../pages'
import LoginPage from '../pages/login'
import PasswordResetPage from '../pages/passwordReset'
import PasswordRequestPage from '../pages/passwordResetRequest'
import AppPreloader from './AppPreloader'
import Submenu from '../components/header/Submenu'
import Dialogs from '../components/dialogs'
import ScrollHandler from '../components/ScrollHandler'
import ResetPage from '../pages/reset'
import AutologinPage from '../pages/autologin'
import { useMobileUi } from '../hooks/ui'
import ConfigStyles from './ConfigStyles'
import Favicon from './Favicon'
import CookieConsent from './CookieConsent'
import { extractSearchParams } from '../utils'

type Props = {
  displayApp?: boolean,
  theme?: ThemeType,
}

// user auto login component
// with auto setting environment
const Autologin = () => {
  const isLoggedIn = useSelector(path(['api', 'login', 'token']))

  return isLoggedIn ? <LoginPage /> : <AutologinPage />
}

// When user is not logged in
// display this component
const LoginPart = () => {
  const location = useLocation()
  const params = extractSearchParams(location.search || '')

  if (params.address && anonymousLoginEnabled()) {
    return (
      <Autologin />
    )
  }

  return (
    <Switch>
      <Route path="/autologin">
        {
          anonymousLoginEnabled()
          ? <AutologinPage redirect />
          : <Redirect to="/" />
        }
      </Route>
      <Route path="/">
        <LoginPage />
      </Route>
    </Switch>
  )
}

let App = ({ displayApp, theme }: Props) => {
  const isMobile = useMobileUi()

  return (
    <Loader id="languages">
      <Router>
        <div className={`theme-${theme}`}>
          <Favicon />
          <ConfigStyles />
          <Switch>
            <Route path="/reset">
              <ResetPage />
            </Route>
            <Route path="/password-reset">
              <PasswordResetPage />
            </Route>
            <Route path="/password-reset-request">
              <PasswordRequestPage />
            </Route>
            {
              !displayApp
                ? <LoginPart />
                : <AppPreloader>
                    <ScrollHandler />
                    <Header />
                    {
                      isMobile ? null :
                      <Submenu />
                    }
                    <IndexPage />
                    <Footer />
                    <CookieConsent />
                  </AppPreloader>
            }
          </Switch>
        </div>
        <Dialogs />
      </Router>
    </Loader>
  )
}

App = compose(
  fetchOnMount({
    fetchAction: () => getLanguages()
  }),
  // @ts-ignore TODO how to connect composed components?
  connect<Props>(state => ({
    displayApp: Boolean(path(['api', 'login', 'token'], state)) && Boolean(path(['api', 'env', 'data', 'id_serving'], state)),
    theme: selectTheme(state),
  }))
)(App)

const ConfigLoader = () => {

  return (
    <Loader id="config">
      <App />
    </Loader>
  )
}

export default fetchOnMount({
  fetchAction: () => getConfig()
})(ConfigLoader)