import React from 'react'
import { DictionaryType } from './types'

const Czech: DictionaryType = {
  id: 'czech',
  labels: {
    pay: 'Platba',
    allergens: 'Alergeny',
    address: 'Adresa',
    datum: 'Datum',
    where: 'Kam to bude?',
    whereRetail: 'Oblast',
    discount: 'Sleva',
    productDescription: 'Popis produktu',
    insertProductIntoCart: 'Přidat do objednávky',
    activeDeliveryTimes: 'Aktivní časy doručení',
    newDeliveryTime: 'Nový čas doručení',
    order: 'Objednávka',
    deliveryTime: 'Čas doručení',
    orderSummary: 'Souhrn objednávky',
    subtotal: 'Mezisoučet',
    priceTotal: 'Celkem',
    price: 'Cena',
    netPrice: 'bez DPH',
    grossPrice: 's DPH',
    cartage: 'Svoz',
    shipping: 'Doprava',
    id: 'IČ',
    id2: 'DIČ',
    back: 'Zpět',
    lostPassword: 'Zapomenuté heslo?',
    commonlyUsed: 'Nejčastěji používané',
    suitableFor: (from: number, to: number) => {
      if (!to && from < 2) {
        return `Vhodné pro ${from} osobu`
      } else if (!to) {
        return `Vhodné pro ${from} osob${from === 1 ? 'u' : from < 5 ? 'y' : ''}`
      }
      return `Vhodné pro ${from} - ${to} osob${to === 1 ? 'u' : to < 5 ? 'y' : ''}`
    },
    accountRegistered: 'Máte už účet na %s?',
    productNotAvailable: 'Na vámi vybrané datum doručení již produkt nelze objednat.',
    datesNull: 'Objednávkový systém je aktuálně uzavřen. Zkuste to prosím později.',
    appearance: 'Zobrazení',
    from: 'Od',
    to: 'Do',
    customer: 'Zákazník',
    user: 'Uživatel',
  },
  cart: {
    address: 'Doručovací adresa',
    datum: 'Datum doručení',
    printH1: 'Návrh objednávky',
  },
  tables: {
    orderHistoryList: {
      heading: 'Objednávky',
      created: 'Datum objednání',
      delivery: 'Datum doručení',
      docnum: 'Číslo objednávky',
      grossprice: 'Cena s DPH',
      netprice: 'Cena bez DPH',
      state: 'Stav objednávky',
      paystatus: 'Stav platby',
      emptyLabel: 'Nemáte žádné aktivní objednávky',
      emptyFilter: 'Žádné objednávky neodpovídají zadaným filtrům',
      invoice: 'Faktura',
      actionname: 'Název události'
    },
    orderHistoryItemList: {
      code: 'Kód položky',
      label: 'Název položky',
      amount: 'Množství',
      unit: 'MJ',
      price: 'Cena',
      orderNumber: 'Objednávka č.',
      back: 'Zpět na seznam objednávek',
    }
  },
  dialogs: {
    changeDeliveryTime: {
      heading: 'Přejete si změnit datum? ',
      info: 'Změnou data opustíte aktuální rozpracovanou objednávku pro dané datum. Můžete se k ní kdykoliv vrátit výběrem aktuálního datumu.',
      note: 'Pozn. náš systém umožňuje mít pro každý den jednu rozpracovanou objednávku (košík).',
      yes: 'Změnit datum',
      no: 'Zavřít',
    },
    changeDeliveryAddress: {
      heading: 'Přejete si změnit oblast?',
      info: 'Změnou oblasti zrušíte objednávku a začnete novou objednávku',
      yes: 'ANO, zrušit nákup',
      no: 'NE, pokračovat v nákupu',
    },
  },
  tags: {
    new: 'Novinka',
    advDays: (n: number) => {
      return `Objednávka ${n} ${n === 1 ? 'den' : n > 5 ? 'dní' : 'dny'} předem.`
    },
    collectionHint: 'Bude připočítán poplatek za svoz',
    granularity: 'K objednání po',
    minOrder: 'Minimální objednávka',
    maxOrder: 'Maximální objednávka',
    time: 'Dnes do',
    timeHint: (time: string) => `Tento produkt je nutné objednat dnes do ${time}, abychom jej stihli doručit na Vámi zvolené datum`,
  },
  register: {
    heading: 'Ještě nemáte svůj účet?',
    checkboxCreateAccount: 'Chci vytvořit účet na %s a souhlasím se zpracováním registračních údajů',
    checkboxFill: 'Automaticky vyplnit data',
    personalConfirm: 'Uděluji souhlas společnosti EXPRESS DELI s.r.o. (IČ 2615 3882) se zpracováním výše uvedených údajů za účelem založení přístupu do uzavřeného objednávkového systému eOrder.',
    termsAndConditionsIntro: 'Souhlasím s',
    termsAndConditionsLink: 'obchodními podmínkami',

    street: 'Ulice a číslo orientační',
    city: 'Město',
    postcode: 'PSČ',

    info1: 'V případě zájmu o registraci účtu pro <b>objednání firemního cateringu</b> prosím pošlete <a href="https://eorder.expressdeli.cz/public/demand-add/step1">poptávku</a>.',
    info2: 'Kromě doručení na Vámi specifikovanou adresu je možné Vaši objednávku vyzvednout v provozovně EXPRESS GRILL, Želetavská 1, Praha 4.',
  },
  forms: {
    order: {
      heading: 'Rekapitulace objednávky',
      deliveryInformation: 'Doručovací údaje',
      floor: 'Patro',
      room: 'Místnost',
      event: 'Událost',
      person: 'Přebírající osoba',
      personRetail: 'Objednávající osoba',
      personName: 'Jméno a příjmení',
      personPhone: 'Telefon',
      paymentMethods: 'Platební metoda',
      additionalData: 'Doplňující údaje',
      note: 'Poznámka',
      internalCode: 'Interní kód',
      submit: 'Odeslat objednávku',
      invoiceInfo: 'Fakturační údaje',
    },
    login: {
      heading: 'Přihlášení',
      email: 'Email',
      password: 'Heslo',
      rememberMe: 'Zapamatovat si mě?',
      submit: 'Přihlásit se',
      info: 'Nemáte ještě účet?',
      register: 'Zaregistrujte se',
    },
    setEnvironment: {
      heading: 'Nastavení objednávky',
      customer: 'Kdo dnes nakupuje?',
      address: 'Kam budeme doručovat?',
      when: 'Kdy budeme objednávku doručovat?',
      serving: 'Jak budeme servírovat?',
      submit: 'Začít nakupovat',
      calendarInfo: 'Objednávky přijímáme do 17.00 v pracovní dny s doručením následující pracovní den',
      orderInfo: 'Naše nabídka se mění v čase. Abychom vám mohli zobrazit naši nabídku, nejprve vyberte kam a kdy budeme doručovat.',
    },
    password: {
      oldPassword: 'Aktuální heslo',
      newPassword: 'Nové heslo',
      success: 'Heslo bylo úspěšně změněno',
      submit: 'Potvrdit změnu hesla',
      resetTitle: 'Obnovení hesla',
      resetConfirm: 'Potvrdit změnu hesla',
      resetRequestTitle: 'Žádost o obnovení hesla',
      resetRequestLabel: 'Zadejte svůj email',
      resetRequestSubmit: 'Poslat odkaz pro obnovení hesla',
    },
  },
  formValidators: {
    tooShort: (size: number) => `Hodnota musí být alespoň ${size} znak${size < 5 ? 'y' : 'ů'} dlouhá`,
    tooLong: (size: number) => `Hodnota musí být maximálně ${size} znak${size < 5 ? 'y' : 'ů'} dlouhá`,
    notEmpty: 'Povinné pole',
    password: 'Heslo nesplňuje kritéria',
    invalidChars: (chars: string) => `Pole obsahuje nepovolené znaky: ${chars}`,
    invalidEmail: 'Neplatný email',
    paymethodSelect: 'Vyberte platební metodu',
  },
  search: {
    heading: 'Vyhledávání',
    categories: 'Kategorie',
    products: 'Produkty',
    info: (phrase: string, num: number) => `Na hledaný výraz <b>${phrase}</b> jsme našli ${num} <b>výsledků</b>.`,
  },
  buttons: {
    showOffer: 'Zobrazit nabídku',
    myAccount: 'Můj účet',
    search: 'Hledat',
    addToOrder: 'Přidat do objednávky',
    changeDeliveryTime: 'Změnit čas doručení',
    finishOrder: 'Pokračovat k rekapitulaci objednávky',
    toCartPage: 'Pokračovat do košíku',
    backToEshop: 'Zpět do obchodu',
    backToCart: 'Zpět do košíku',
    paymentRepeat: 'Opakovat platbu',
    paygateGo: 'Přejít na platební bránu',
    createNewOrder: 'Vytvořit novou objednávku',
    withoutRegistration: 'Nakoupit bez registrace',
    withoutRegistrationDetail: 'Registrace je volitelná na konci nákupu',
    filter: 'Filtrovat',
    filterUse: 'Použít filtr',
  },
  actions: {
    pay: 'Zaplatit objednávku',
    logout: 'Odhlásit se',
    anonymLogout: 'Zrušit nákup',
    detailShow: 'Zobrazit popis produktu',
    detailHide: 'Skrýt popis produktu',
    close: 'Zavřít',
    readActuality: 'Přečíst celou aktualitu',
    showMore: 'Zobrazit více',
    showLess: 'Zobrazit méně',
    contact: 'Kontaktujte nás.',
    loadMore: 'Načíst další',
  },
  placeholders: {
    search: 'Zadejte hledaný výraz',
    choose: 'Vyberte…',
    deliveryTime: 'Vyberte čas doručení…',
    anotherPaymethod: 'Zvolit jinou platební metodu',
    paymethod: 'Zvolte platební metodu'
  },
  info: {
    needHelp: 'Potřebujete poradit?',
    emptyOrder: 'Zatím jste nepřidali nic do své objednávky',
    minOrder: (amount: string) => `Do minimální objednávky zbývá <b>${amount} Kč</b>.`,
  },
  thankYou: {
    heading: 'Děkujeme za Vaši objednávku',
    headingCanceled: 'Zrušená platba',
    descriptionCanceled: 'Vaše objednávka nebyla uhrazena.',
    descriptionCanceledInstructions: 'Můžete se vrátit na platební bránu a uhradit objednávku znovu.',
    title: 'Objednávka odeslána! Děkujeme Vám.',
    description: 'Objednávku zpracujeme v nejkratším možném termínu během naši provozní doby.',
    orderNumber: 'Číslo objednávky',
    orderPaid: 'Objednávka úspěšně zaplacena',
    headingError: 'Chyba platby',
    orderPaidError: 'Při platbě nastala chyba',
    orderPaidDescriptionError: 'Při platbě bohužel nastala chyba. Můžete zkusit objednávku zaplatit ještě jednou, nebo nás kontaktujte.',
    paygateDescription: 'Zvolili jste platební metodu Kartou Online (brána ČSOB). Budete přesměrování na platební bránu.',
    paygateDescriptionSodexo: 'Zvolili jste platební metodu Kartou Online (brána Sodexo). Budete přesměrování na platební bránu.',
  },
  account: {
    accountTitle: 'Účet',
    passwordTitle: 'Změna hesla',
    customersTitle: 'Odběratelé',
    historyTitle: 'Historie objednávek',
  },
  html: {
    addProductToCartInfo:
      `
      <p> Při výběru rozvozového okna je nutné počítat s časem na přípravu vaší objednávky. </p>
      <p>
        <b>Příklad:</b> Pokud vaše akce má plánovaný začátek na 9:00 vyberte rozvozové okno 7:45-8:30.
        Zákazník se zavazuje, že ve vybraném čase zajistí rychlé převzetí objednávky. Při volbě času, prosím, berte toto na vědomí.
      </p>
      `,
    addProductToCartInfoRetail:
      `
      <p> O přesnějším intervalu času doručení vás budeme informovat nejpozději v den doručení. Děkujeme za pochopení. </p>
      `
  },
  cookie: {
    consent: 'Naše webové stránky vyžadují pro svoji správnou činnost cookies. Dalším používáním těchto stránek s tím vyjadřujete souhlas.',
    ok: 'Rozumím',
  },
  errors: {
    resetPasswordInvalidToken: 'Platnost odkazu již vypršela, nechte si, prosím, poslat nový',
    changePassword404: 'Změna hesla se nezdařila, zřejmě nesouhlasí aktuální heslo.',
    page404: 'Stránka neexistuje, pokračovat do',
    page404Link: 'obchodu',
    page404WrongSegment: ({ segment, Link }) => <div>Jste přihlášen {segment === 'r' ? 'v soukromém' : 've firemním'} segmentu, pro pokračovaní je nutné se nejprve <Link>odhlásit zde</Link>. Vaše rozpracovaná objednávka zůstane zachována.</div>,
    unknownEmail: 'Neznámý email',
    credentials: 'Neplatné přihlašovací údaje',
    AUTH: 'Přihlášení se nezdařilo. Zkuste to prosím znovu, nechte si obnovit zapomenuté heslo nebo nás kontaktujte.',
    OTHER: 'V aplikaci došlo k chybě, o níž jsme byli informováni. Pokud se Vám nedaří pokračovat, kontaktujte nás.',
    DATE: 'Na tento den již není možné učinit objednávku. Prosím vyberte jiné datum.',
    MAXQTY: 'Sloučením časů dodání by bylo překročeno maximální objednatelné množství produktu.',
    PAYNF: 'Objednávka nebyla nalezena, prosím kontaktujte nás.',
    PAID: 'Objednávka již byla zaplacena.',
    QTYLT: 'Množství je menší než minimální objednatelné množství produktu',
    QTYGT: 'Množství je větší než maximální objednatelné množství produktu',
    QTYDMG: 'Množství neodpovídá požadovanému kroku',
    MAIL: 'Pro tento email nelze provést novou registraci. Váže se k jinému účtu. Zadejte jiný email.',
  },
  footer: {
    about: 'O společnosti',
    actuality: 'Aktuality',
    actualityMore: 'Zobrazit další novinky',
  }
}

export default Czech
