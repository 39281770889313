import React from 'react'
import { path } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import { setUi } from '../../../store/actions/ui'
import { postProductToOrder } from '../../../store/actions/api'
import { ProductType } from '../../../store/types/products'
import Amount from './Amount'
import { useDictionary } from '../../../dictionary'
import { useMobileUi } from '../../../hooks/ui'
import { useRetail } from '../../../hooks/api'

type Props = {
  product: ProductType,
}

const MakeOrder = ({ product }: Props) => {
  const dispatch = useDispatch()
  const dictionary = useDictionary()
  const isMobile = useMobileUi()
  const isRetail = useRetail()
  const activeDeliveryTime = useSelector(state => path(['api', 'order', 'cart', 'dlv_times', 0], state))
  const cartId = useSelector(state => path(['api', 'order', 'cart', 'id'], state)) as number
  const toAdd = useSelector(state => path(['ui', 'amounts', product.id], state)) as number

  const onClickBusiness = () => dispatch(setUi({ openedDialog: { id: 'add-to-order', product: product } }))
  const onClickRetial = () => {
    if (!activeDeliveryTime) {
      return onClickBusiness()
    } else {
      dispatch(postProductToOrder({
        cartId,
        amount: toAdd || product.props.minorder || 1,
        timeCode: path(['timecode'], activeDeliveryTime) || '',
        productId: product.id,
      }))
    }
  }

  return (
    <div className={isMobile ? 'mobile-make-order' : 'make-order d-inline-block float-right'}>
      <Amount product={product} />
      <button
        className={`btn${!product.available ? ' is-disabled' : ''}`}
        onClick={isRetail ? onClickRetial : onClickBusiness}
        disabled={!product.available}
        title={product.available ? undefined : dictionary.labels.productNotAvailable}
      >
        {dictionary.buttons.addToOrder}
      </button>
    </div>
  )
}

export default MakeOrder
